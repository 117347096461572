import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Link, navigate } from 'gatsby';
import '../scss/header.scss';
import BurgerMenu from './burger-menu';
import useMediaQuery from '../utils/use-media-query';

const HeaderWrapper = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: ${({ hasBackground }: any) =>
    hasBackground ? 'rgb(104, 158, 205)' : 'transparent'};
  box-shadow: ${({ hasBackground }: any) =>
    hasBackground ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none'};
  padding: 0.5em 1em;
  transition: background-color 0.3s ease-in-out, box-shadow 0.4s ease;

  @media (max-width: 767px) {
    background-color: #3a2395;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
`;

const HeaderLogo = styled.img`
  height: 50px;
  margin-top: 0.3rem;
  @media (min-width: 768px) {
    width: 260px;
  }

  @media (min-width: 992px) {
    height: 70px;
    width: unset;
  }
`;
const ListPortfolio = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  background: rgba(235 235 235 / 0.8);
  color: #3a2395;
  margin: 0;
  width: 13rem;
  border-radius: 0 1rem 1rem 1rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  list-style: none;

  li {
    display: flex;
    gap: 0.5rem;
    alignitems: stretch;
    margin: 0;
    border-bottom: 1px solid #ebebeb;

    :nth-child(3) {
      border-bottom: unset;
    }

    img {
      width: 1.5rem;
    }
  }
`;
const NavBar = styled.nav`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 73px;
  right: ${({ show }: { show: boolean }) => (show ? 0 : -100)}%;
  width: 100%;
  max-width: 240px;
  background-color: #ffffff;
  transition: right 0.4s ease;
  padding: 24px 0;
  box-shadow: 0px 3px 6px #00000029;
  z-index: -1;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    position: relative;
    background-color: transparent;
    top: 0;
    height: auto;
    width: auto;
    max-width: unset;
    padding-top: auto;
    right: auto;
    z-index: auto;
    box-shadow: none;
    padding: 0;
  }
`;

const SpanNavbar = styled.span`
  cursor: pointer;

  ::before {
    content: '•';
    color: white;
    margin-right: 12px;
    position: relative;
    top: 3px;
  }
`;
type IPortfolioRoutes = {
  img: string;
  value: string;
  sectionId: string;
};
type INavBarLinks = {
  id: string;
  title: string;
  path: string;
  portfolioItems: IPortfolioRoutes[] | [];
};
const NAVBAR_LINKS: INavBarLinks[] = [
  {
    id: 'about',
    title: 'Proyectat',
    path: '/about',
    portfolioItems: [],
  },
  {
    id: 'portfolio',
    title: 'Portafolio',
    path: '/vida-y-acumulacion',
    portfolioItems: [
      { img: 'heart.svg', value: 'Vida', sectionId: 'life' },
      { img: 'health_and_safety.svg', value: 'Salud', sectionId: 'health' },
      { img: 'home.svg', value: 'Patrimonio', sectionId: 'heritage' },
    ],
  },
  {
    id: 'talent',
    title: 'Talento Proyectat',
    path: '/talent',
    portfolioItems: [],
  },
  {
    id: 'blogs',
    title: 'Noticias',
    path: '/blogs',
    portfolioItems: [],
  },
];

const PopUpPortfolio = ({
  portfolioItems,
}: {
  portfolioItems: IPortfolioRoutes[];
}) => {
  const [showMenuPortfolio, setShowMenuPortfolio] = useState(false);

  const navigateTo = (idSection: string) => {
    navigate(`/vida-y-acumulacion/#${idSection}`);
    setTimeout(() => {
      const yOffset = -105;
      const element = document.getElementById(idSection);
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 100);
  };
  return (
    <div
      css={{
        position: 'relative',
        color: 'white',

        padding: '0.5rem 1.8rem 0.5rem 1.2rem',
        ':hover': {
          backgroundColor: '#0a286a',
          borderRadius: '0.5em 0.5em 0 0',
        },
      }}
      onMouseEnter={() => setShowMenuPortfolio(showMenu => !showMenu)}
      onMouseLeave={() => setShowMenuPortfolio(showMenu => !showMenu)}
    >
      <SpanNavbar onClick={() => navigate(`/vida-y-acumulacion`)}>
        Portafolio
      </SpanNavbar>
      {showMenuPortfolio && (
        <ListPortfolio>
          {portfolioItems.map(item => (
            <li
              key={`portfolio-${item.sectionId}`}
              css={{ cursor: 'pointer' }}
              onClick={() => navigateTo(item.sectionId)}
            >
              <img src={`/images/${item.img}`} alt="Icon" />
              {item.value}
            </li>
          ))}
        </ListPortfolio>
      )}
    </div>
  );
};
const LinkNavBar = ({
  index,
  isOnTop,
  transparentOnTop,
  toggleMenu,
  path,
  title,
}) => {
  return (
    <Link
      key={`link-${index}`}
      className="text-primary header-link"
      css={theme => ({
        textDecoration: 'none',
        padding: '.5rem 2rem',
        fontWeight: 100,
        fontSize: '20px',
        position: 'relative',
        transition: 'all 150ms ease',
        borderRadius: '0.5em',

        color: '#6DA3D5',
        ':hover': {
          color: 'white',
          backgroundColor: '#0A286A',
        },
        '@media (min-width: 768px)': {
          color: isOnTop && transparentOnTop ? 'white' : 'white',
        },
      })}
      onClick={toggleMenu}
      to={path}
    >
      {title}
    </Link>
  );
};

export interface HeaderProps {
  transparentOnTop?: boolean;
}

const Header: React.FC<HeaderProps> = ({ transparentOnTop }) => {
  const [isOnTop, setIsOnTop] = useState(true);
  const [showMenu, setShowMenu] = useState(false);

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const scrollHandler = () => {
    const { scrollTop } = document.documentElement;

    setIsOnTop(scrollTop === 0);
  };

  const toggleMenu = () => setShowMenu(!showMenu);

  useEffect(() => {
    // Initial call to verify position on the screen
    scrollHandler();

    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <HeaderWrapper hasBackground={!isOnTop || !transparentOnTop}>
      <HeaderContent className="container">
        <Link to="/">
          <HeaderLogo
            src="/images/Logo-blanco.png"
            className="mb-0"
            alt="Proyectat logo"
          />
        </Link>
        <div css={{ '@media (min-width: 768px)': { display: 'none' } }}>
          <BurgerMenu opened={showMenu} onClick={toggleMenu} />
        </div>
        <NavBar show={showMenu}>
          {NAVBAR_LINKS.map(({ id, path, title, portfolioItems }, index) =>
            id != 'portfolio' ? (
              <LinkNavBar
                key={`link-navbar1-${index}`}
                index={index}
                isOnTop={isOnTop}
                transparentOnTop={transparentOnTop}
                toggleMenu={toggleMenu}
                path={path}
                title={title}
              />
            ) : (
              <>
                {isDesktop ? (
                  <PopUpPortfolio portfolioItems={portfolioItems} />
                ) : (
                  <LinkNavBar
                    key={`link-navbar2-${index}`}
                    index={index}
                    isOnTop={isOnTop}
                    transparentOnTop={transparentOnTop}
                    toggleMenu={toggleMenu}
                    path={path}
                    title={title}
                  />
                )}
              </>
            )
          )}
        </NavBar>
      </HeaderContent>
    </HeaderWrapper>
  );
};

Header.defaultProps = {
  transparentOnTop: true,
};

export default Header;
