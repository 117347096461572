import React, { useState } from 'react';
import '../scss/quote-form.scss';
import Calendar from 'react-calendar';
require('react-calendar/dist/Calendar.css');
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from 'react-redux';
import { handleShowModalQuote } from '../redux/actions';

import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import Button from './button';
import Swal from 'sweetalert2';
import {
  SWEET_MESSAGES,
  API_HUBSPOT,
  HEADERS_HUBSPOT,
} from '../constants/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      boxShadow: theme.shadows[5],
    },
  })
);

const SCHEDULE_OPTIONS: string[] = [
  '10:00 a.m.',
  '11:00 a.m.',
  '12:00 p.m.',
  '1:00 p.m.',
  '2:00 p.m.',
  '3:00 p.m.',
  '4:00 p.m.',
  '5:00 p.m.',
];

export interface QuoteFormProps {
  showModalQuote: boolean;
  dispatch: any;
}

const QuoteForm: React.FC<QuoteFormProps> = ({ dispatch, showModalQuote }) => {
  const classes = useStyles();

  const [selectedSchedule, setSelectedSchedule] = useState<string>('');
  const [selectedDate, onChange] = useState(new Date());

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onChange',
  });

  const onCloseModal = () => dispatch(handleShowModalQuote(false));

  const showAlert = (title, text, icon, confirmButtonText = 'Cerrar') =>
    Swal.fire({ title, text, icon, confirmButtonText });

  const onSelectSchedule = e =>
    setSelectedSchedule(e.currentTarget.dataset.option);

  const parseDate = selectedDate => {
    const date = new Date(selectedDate);
    const months = [
      'enero',
      'febrero',
      'marzo',
      'abril',
      'mayo',
      'junio',
      'julio',
      'agosto',
      'septiembre',
      'octubre',
      'noviembre',
      'diciembre',
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    return `${day} de ${month} del ${year}`;
  };

  const onSubmit = data => {
    if (!!selectedDate && !!selectedSchedule) {
      const body = {
        properties: [],
      };

      Object.keys(data).map(key =>
        body.properties.push({ property: key, value: data[key] })
      );

      body.properties.push(
        {
          property: 'desired_date',
          value: parseDate(selectedDate),
        },
        {
          property: 'form_type',
          value: 'Cotización',
        },
        {
          property: 'desired_schedule',
          value: selectedSchedule,
        }
      );

      fetch(API_HUBSPOT.URL, {
        method: 'POST',
        headers: HEADERS_HUBSPOT,
        body: JSON.stringify(body),
      })
        .then(res => res.json())
        .then(
          result => {
            onCloseModal();
            if (result.statusCode === 200) {
              reset();
              showAlert(
                SWEET_MESSAGES.SUCCESS,
                API_HUBSPOT.SUCCESS_MESSAGE,
                'success'
              );
            } else if (!!result.status && !!result.status.message) {
              const errorMessage =
                result.status.message === API_HUBSPOT.ERROR_CONTACT_EXISTS
                  ? API_HUBSPOT.CONTACT_EXISTS_MESSSAGE
                  : API_HUBSPOT.GENERAL_ERROR_MESSAGE;
              showAlert(SWEET_MESSAGES.ERROR, errorMessage, 'error');
            }
          },
          () => {
            onCloseModal();
            showAlert(
              SWEET_MESSAGES.ERROR,
              API_HUBSPOT.GENERAL_ERROR_MESSAGE,
              'error'
            );
          }
        );
    }
  };

  return (
    <section className="QuoteForm__back">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModalQuote}
        onClose={onCloseModal}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModalQuote}>
          <div className={classes.paper}>
            <FontAwesomeIcon
              onClick={onCloseModal}
              className="mr-3"
              size="lg"
              icon={faTimesCircle}
            />
            <h3 className="QuoteForm__title">
              ¡Sólo necesitamos algunos datos para ponerte en contacto con un
              asesor financiero especializado!
            </h3>
            <form onSubmit={handleSubmit(onSubmit)} className="QuoteForm__form">
              <div className="QuoteForm__form-controls_container">
                <div className="QuoteForm__form-control">
                  <label htmlFor="nombreQuote">Nombre</label>
                  <input
                    type="text"
                    id="nombreQuote"
                    name="firstname"
                    ref={register({
                      required: true,
                      pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    })}
                    className={errors.firstname && 'invalid'}
                  />
                </div>
                <div className="QuoteForm__form-control">
                  <label htmlFor="cityQuote">Ciudad</label>
                  <select
                    id="cityQuote"
                    name="city"
                    ref={register({ required: true })}
                    className={errors.city && 'invalid'}
                  >
                    <option value="Medellín">Medellín</option>
                    <option value="Cartagena">Cartagena</option>
                    <option value="Santa Marta">Santa Marta</option>
                    <option value="Valledupar">Valledupar</option>
                    <option value="Barranquilla">Barranquilla</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Pereira">Pereira</option>
                    <option value="Manizales">Manizales</option>
                    <option value="Popayán">Popayán</option>
                    <option value="Villavicencio">Villavicencio</option>
                    <option value="Bucaramanga">Bucaramanga</option>
                    <option value="Montería">Montería</option>
                    <option value="Bogotá">Bogotá</option>
                    <option value="Cali">Cali</option>
                    <option value="Pasto">Pasto</option>
                  </select>
                  <FontAwesomeIcon
                    className="arrow_down"
                    size="lg"
                    icon={faCaretDown}
                  />
                </div>
                <div className="QuoteForm__form-control">
                  <label htmlFor="emailQuote">E-mail</label>
                  <input
                    type="email"
                    id="emailQuote"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    })}
                    className={errors.email && 'invalid'}
                  />
                </div>
                <div className="QuoteForm__form-control">
                  <label htmlFor="phoneQuote">Teléfono de contacto</label>
                  <input
                    type="phone"
                    id="phoneQuote"
                    name="phone"
                    ref={register({
                      required: true,
                      pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    })}
                    className={errors.phone && 'invalid'}
                  />
                </div>
              </div>
              <div className="QuoteForm__form-date_container">
                <div className="QuoteForm__form-calendar">
                  <img
                    className="QuoteForm__form-calendar-img"
                    src="/images/27-28.png"
                    alt="Logo caracteristica"
                  />
                  <p className="QuoteForm__form-calendar-text">
                    ¡Agenda tu llamada con Alejandra ahora mismo!
                  </p>
                  <div className="QuoteForm__form-calendar-container">
                    <Calendar
                      onChange={onChange}
                      value={selectedDate}
                      minDate={new Date()}
                    />
                  </div>
                </div>
                <div className="QuoteForm__form-schedule">
                  <h3 className="QuoteForm__title">
                    ¿En qué horario te parece mejor?
                  </h3>
                  <p className="QuoteForm__form-schedule-subtitle">
                    UTC horario de Colombia
                  </p>
                  <div className="QuoteForm__form-schedule-options">
                    {SCHEDULE_OPTIONS.map((OPTION, index) => (
                      <div
                        key={`option-${index}`}
                        className={
                          (OPTION === selectedSchedule ? 'active' : '') +
                          ' QuoteForm__form-schedule-option'
                        }
                        data-option={OPTION}
                        onClick={onSelectSchedule}
                      >
                        <p>{OPTION}</p>
                      </div>
                    ))}
                  </div>
                  <Button
                    fontSize="16px"
                    fontWeight="400"
                    marginTop="0"
                    className="QuoteForm__form-schedule-button"
                  >
                    Agendar ahora
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
    </section>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  QuoteForm
);
