import React, { useEffect, useState } from 'react';
import '../scss/postulation-form.scss';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { connect } from 'react-redux';
import { handleShowModalPostulation } from '../redux/actions';

import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import Button from './button';
import Swal from 'sweetalert2';
import {
  SWEET_MESSAGES,
  API_HUBSPOT,
  HEADERS_HUBSPOT,
} from '../constants/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      boxShadow: theme.shadows[5],
    },
  })
);

export interface PostulationFormProps {
  showModalPostulation: boolean;
  dispatch: any;
}

const PostulationForm: React.FC<PostulationFormProps> = ({
  dispatch,
  showModalPostulation,
}) => {
  const classes = useStyles();
  const [selectedCV, setSelectedCV] = useState<string>('');
  const { register, handleSubmit, errors, reset, watch } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const FileList = watch('curriculum_vitae') as FileList;
    if (!!FileList && FileList.length > 0) {
      setSelectedCV(FileList[0].name);
    } else {
    }
  }, [watch('curriculum_vitae')]);

  const onCloseModal = () => {
    setSelectedCV('');
    dispatch(handleShowModalPostulation(false));
  };

  const showAlert = (title, text, icon, confirmButtonText = 'Cerrar') =>
    Swal.fire({ title, text, icon, confirmButtonText });

  const onSubmit = data => {
    const body = {
      properties: [],
    };

    Object.keys(data).map(key =>
      key === 'curriculum_vitae'
        ? body.properties.push({
            property: key,
            value: new File([data[key][0]], 'Hoja de vida', {
              type: 'application/pdf',
            }),
          })
        : body.properties.push({ property: key, value: data[key] })
    );

    body.properties.push({ property: 'form_type', value: 'Postulación' });

    fetch(API_HUBSPOT.URL, {
      method: 'POST',
      headers: HEADERS_HUBSPOT,
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(
        result => {
          onCloseModal();
          if (result.statusCode === 200) {
            reset();
            showAlert(
              SWEET_MESSAGES.SUCCESS,
              API_HUBSPOT.POSTULATION_MESSAGE,
              'success'
            );
          } else if (!!result.status && !!result.status.message) {
            const errorMessage =
              result.status.message === API_HUBSPOT.ERROR_CONTACT_EXISTS
                ? API_HUBSPOT.CONTACT_EXISTS_MESSSAGE
                : API_HUBSPOT.GENERAL_ERROR_MESSAGE;
            showAlert(SWEET_MESSAGES.ERROR, errorMessage, 'error');
          }
        },
        () => {
          onCloseModal();
          showAlert(
            SWEET_MESSAGES.ERROR,
            API_HUBSPOT.GENERAL_ERROR_MESSAGE,
            'error'
          );
        }
      );
  };

  return (
    <section className="PostulationForm__back">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModalPostulation}
        onClose={onCloseModal}
        closeAfterTransition
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModalPostulation}>
          <div className={classes.paper}>
            <FontAwesomeIcon
              onClick={onCloseModal}
              className="mr-3"
              size="lg"
              icon={faTimesCircle}
            />
            <h3 className="PostulationForm__title">
              ¡Agradecemos tu interés por querer hacer parte de la familia
              Proyectat! Necesitamos saber algunos datos antes de ponernos en
              contacto
            </h3>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="PostulationForm__form"
            >
              <div className="PostulationForm__form-controls_container">
                <div className="PostulationForm__form-control">
                  <label htmlFor="nombrePostulation">Nombre</label>
                  <input
                    type="text"
                    id="nombrePostulation"
                    name="firstname"
                    ref={register({
                      required: true,
                      pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    })}
                    className={errors.firstname && 'invalid'}
                  />
                </div>
                <div className="PostulationForm__form-control">
                  <label htmlFor="cityPostulation">Ciudad</label>
                  <select
                    id="cityPostulation"
                    name="city"
                    ref={register({ required: true })}
                    className={errors.city && 'invalid'}
                  >
                    <option value="Medellín">Medellín</option>
                    <option value="Cartagena">Cartagena</option>
                    <option value="Santa Marta">Santa Marta</option>
                    <option value="Valledupar">Valledupar</option>
                    <option value="Barranquilla">Barranquilla</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Pereira">Pereira</option>
                    <option value="Manizales">Manizales</option>
                    <option value="Popayán">Popayán</option>
                    <option value="Villavicencio">Villavicencio</option>
                    <option value="Bucaramanga">Bucaramanga</option>
                    <option value="Montería">Montería</option>
                    <option value="Bogotá">Bogotá</option>
                    <option value="Cali">Cali</option>
                    <option value="Pasto">Pasto</option>
                  </select>
                  <FontAwesomeIcon
                    className="arrow_down"
                    size="lg"
                    icon={faCaretDown}
                  />
                </div>
                <div className="PostulationForm__form-control">
                  <label htmlFor="emailPostulation">E-mail</label>
                  <input
                    type="email"
                    id="emailPostulation"
                    name="email"
                    ref={register({
                      required: true,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    })}
                    className={errors.email && 'invalid'}
                  />
                </div>
                <div className="PostulationForm__form-control">
                  <label htmlFor="phonePostulation">Teléfono de contacto</label>
                  <input
                    type="phone"
                    id="phonePostulation"
                    name="phone"
                    ref={register({
                      required: true,
                      pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                    })}
                    className={errors.phone && 'invalid'}
                  />
                </div>
                <div className="PostulationForm__form-control">
                  <label htmlFor="linkedinPostulation">
                    URL perfil LinkedIN
                  </label>
                  <input
                    type="text"
                    id="linkedinPostulation"
                    name="url_linkedin_profile"
                    ref={register({
                      pattern: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                    })}
                    className={errors.url_linkedin_profile && 'invalid'}
                  />
                </div>
              </div>
              <div className="PostulationForm__form-controls_container">
                <div
                  className="PostulationForm__form-control"
                  id="cvPostulation"
                >
                  <label htmlFor="linkedinPostulation">
                    Adjunte su hoja de vida
                  </label>
                  <input
                    type="file"
                    accept="application/pdf"
                    id="cvInputPostulation"
                    name="curriculum_vitae"
                    ref={register({
                      required: true,
                    })}
                    className={errors.curriculum_vitae && 'invalid'}
                  />
                  {errors.curriculum_vitae && (
                    <p className="empty__cv">Este campo es obligatorio</p>
                  )}
                  <p>{selectedCV}</p>
                </div>
              </div>
              <Button
                fontSize="16px"
                fontWeight="400"
                marginTop="0"
                className="PostulationForm__form-button"
              >
                Postularme ahora
              </Button>
            </form>
          </div>
        </Fade>
      </Modal>
    </section>
  );
};

export default connect(state => ({
  showModalPostulation: state.showModalPostulation,
}))(PostulationForm);
