import React from 'react';

export interface ButtonProps {
  fontSize?: string;
  fontWeight?: string | any;
  paddingRight?: string;
  paddingLeft?: string;
  marginTop?: string;
  className?: string;
  dark?: boolean;
  onClickEvent?: () => void;
}

const Button: React.FC<ButtonProps> = props => {
  return (
    <button
      className={(!!props.className ? props.className : '') + ' app_button'}
      css={{
        background: props.dark ? '#3A2395' : '#D6E848',
        padding: '8px 12px',
        borderRadius: '9px',
        cursor: 'pointer',
        color: props.dark ? '#D6E848' : '#281C70',
        letterSpacing: '0.03px',
        border: 'none',
        outline: 'none',
        fontSize: !!props.fontSize ? props.fontSize : '1rem',
        fontWeight: !!props.fontWeight ? props.fontWeight : '100',
        paddingRight: !!props.paddingRight ? props.paddingRight : '12px',
        paddingLeft: !!props.paddingLeft ? props.paddingLeft : '12px',
        marginTop: !!props.marginTop ? props.marginTop : 'auto',

        ':hover': {
          background: props.dark ? '#301d7b' : '#cadb49',
        },

        '@media (max-width: 450px)': {
          fontSize: '16px',
          lineHeight: '22px',
        },
      }}
      onClick={props.onClickEvent}
    >
      {props.children}
    </button>
  );
};

export default Button;
