import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from 'react-reveal/Fade';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { graphql, useStaticQuery, Link } from 'gatsby';
import '../scss/footer.scss';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import {
  API_HUBSPOT,
  HEADERS_HUBSPOT,
  SWEET_MESSAGES,
} from '../constants/constants';

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { site } = useStaticQuery(query);
  const { register, handleSubmit, formState, errors, reset } = useForm({
    mode: 'onChange',
  });

  const onSubmit = data => {
    const body = {
      properties: [],
    };

    Object.keys(data).map(key =>
      body.properties.push({ property: key, value: data[key] })
    );

    body.properties.push({ property: 'form_type', value: 'Cotización' });

    fetch(API_HUBSPOT.URL, {
      method: 'POST',
      headers: HEADERS_HUBSPOT,
      body: JSON.stringify(body),
    })
      .then(res => res.json())
      .then(
        result => {
          if (!!result.status && result.status === 'error') {
            const errorMessage =
              result.error === API_HUBSPOT.ERROR_CONTACT_EXISTS
                ? API_HUBSPOT.CONTACT_EXISTS_MESSSAGE
                : API_HUBSPOT.GENERAL_ERROR_MESSAGE;
            Swal.fire({
              title: SWEET_MESSAGES.ERROR,
              text: errorMessage,
              icon: 'error',
              confirmButtonText: 'Cerrar',
            });
          } else {
            reset();
            Swal.fire({
              title: SWEET_MESSAGES.SUCCESS,
              text: API_HUBSPOT.SUCCESS_MESSAGE,
              icon: 'success',
              confirmButtonText: 'Cerrar',
            });
          }
        },
        error =>
          Swal.fire({
            title: SWEET_MESSAGES.ERROR,
            text: API_HUBSPOT.GENERAL_ERROR_MESSAGE,
            icon: 'error',
            confirmButtonText: 'Cerrar',
          })
      );
  };

  const {
    instagram,
    facebook,
    linkedin,
    youtube,
    tiktok,
  } = site.siteMetadata.socialMedia;

  return (
    <div className="py-5">
      <div className="container">
        <Fade right>
          <h2 className="text-center">Entremos en contacto</h2>
        </Fade>
        <div className="container_form">
          <div className="row">
            <div className="col-md">
              <Fade left>
                <p className="footer_description thin">
                  Agenda ahora una{' '}
                  <Link className="hover_bg_yellow" to="/about">
                    asesoría virtual
                  </Link>{' '}
                  con alguno de nuestros consultores financieros especializados
                </p>
                <p className="footer_address thin">
                  Dirección:{' '}
                  <a
                    className="hover_bg_yellow"
                    href="https://www.google.com/maps/place/Av.+9+Nte.+%2318n29+N+25+Oficina+301,+Cali,+Valle+del+Cauca/@3.461799,-76.5321506,17z/data=!4m13!1m7!3m6!1s0x8e30a66cbd84ba07:0x52370e11f65dd0e8!2sAv.+9+Nte.+%2318n29+N+25+Oficina+301,+Cali,+Valle+del+Cauca!3b1!8m2!3d3.4621516!4d-76.5324117!3m4!1s0x8e30a66cbd84ba07:0x52370e11f65dd0e8!8m2!3d3.4621516!4d-76.5324117"
                    target="_blank"
                  >
                    Av. 9 Norte # 18 N 25 Oficina 301 - Cali, Colombia.
                  </a>
                </p>
                <p className="footer_tel thin">
                  Tel. Servicio al Cliente:
                  <a
                    className="hover_bg_yellow"
                    href="https://linktr.ee/Proyectat"
                    target="_blank"
                  >
                    3507367033
                  </a>
                </p>
              </Fade>
            </div>
            <div className="col-md">
              <Fade right>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <section>
                    <div className="container_input">
                      <label htmlFor="nombreInput">Nombre*</label>
                      <input
                        type="text"
                        id="nombreInput"
                        placeholder="Nombre"
                        name="firstname"
                        ref={register({ required: true })}
                        className={errors.firstname && 'invalid'}
                      />
                    </div>
                    <div className="container_input">
                      <label htmlFor="emailInput">E-mail*</label>
                      <input
                        type="email"
                        id="emailInput"
                        placeholder="E-mail*"
                        name="email"
                        ref={register({
                          required: true,
                          pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        })}
                        className={errors.email && 'invalid'}
                      />
                    </div>
                    <div className="container_input">
                      <label htmlFor="mensajeInput">Mensaje*</label>
                      <textarea
                        css={{
                          '@media (min-width: 767px)': { marginTop: '0.4rem' },
                        }}
                        typeof="text"
                        id="mensajeInput"
                        placeholder="Mensaje"
                        name="help_message"
                        ref={register({ required: true })}
                        className={errors.help_message && 'invalid'}
                      ></textarea>
                    </div>
                    <div className="container_button">
                      <button type="submit" disabled={!formState.isValid}>
                        <FontAwesomeIcon
                          className="mr-3"
                          size="lg"
                          icon={faChevronRight}
                        />
                      </button>
                    </div>
                  </section>
                </form>
              </Fade>
            </div>
          </div>

          <div className="row footer_links">
            <div className="mt-4 d-flex justify-content-center align-items-center container_logo">
              <Link to="/">
                <img className="logo" src="/images/Logo-gris.png" />
              </Link>
            </div>

            <div className="mt-4 col-md-auto d-flex justify-content-center align-items-center text-center container_links">
              <Link className="mx-2 footer_link" to="/politics">
                Política de privacidad
              </Link>
              {/* <Link className="mx-2 footer_link" to="about">
                contáctenos
              </Link>
              <Link className="mx-2 footer_link" to="about">
                ayuda
              </Link> */}
            </div>
            <div className="mt-4 col-md d-flex justify-content-center align-items-center section_brand_icons">
              <a href={linkedin} target="_blank">
                <FontAwesomeIcon
                  className="mr-3"
                  size="lg"
                  icon={faLinkedinIn}
                />
              </a>
              <a href={instagram} target="_blank">
                <FontAwesomeIcon
                  className="mr-3"
                  size="lg"
                  icon={faInstagram}
                />
              </a>
              <a href={facebook} target="_blank">
                <FontAwesomeIcon
                  className="mr-3"
                  size="lg"
                  icon={faFacebookF}
                />
              </a>
              <a href={youtube} target="_blank">
                <FontAwesomeIcon className="mr-3" size="lg" icon={faYoutube} />
              </a>
              <a href={tiktok} target="_blank">
                <FontAwesomeIcon className="mr-3" size="lg" icon={faTiktok} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query About {
    site {
      siteMetadata {
        socialMedia {
          facebook
          linkedin
          instagram
          youtube
          tiktok
        }
      }
    }
  }
`;

export default Footer;
