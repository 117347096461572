import React from 'react';
import Footer from './footer';
import Header from './header';
import QuoteForm from './quote-form';
import PostulationForm from './postulation-form';

export interface LayoutProps {
  transparentOnTop?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ children, transparentOnTop }) => (
  <div>
    <Header transparentOnTop={transparentOnTop} />
    <div className="hero-min">{children}</div>
    <Footer />
    <QuoteForm />
    <PostulationForm />
  </div>
);

export default Layout;
