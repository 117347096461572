import { ACTION_TYPES } from '../../constants/constants';

export const handleShowModalQuote = (newState: boolean) => ({
  type: ACTION_TYPES.SHOW_MODAL_QUOTE,
  newState,
});

export const handleShowModalPostulation = (newState: boolean) => ({
  type: ACTION_TYPES.SHOW_MODAL_POSTULATION,
  newState,
});
