import React, { CSSProperties } from 'react';
import { css } from '@emotion/core';

export interface BurgerMenuProps {
  opened?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

const BurgerMenu: React.FC<BurgerMenuProps> = ({ opened, onClick, style }) => {
  return (
    <div css={{ zIndex: 100 }} {...{ onClick, style }}>
      <div
        css={css`
          position: relative;
          cursor: pointer;
          width: 35px;
          height: 3px;
          background-color: white;
          transform: rotate(${opened ? 45 : 0}deg);
          border-radius: 10px;
          transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

          :after,
          :before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: white;
            border-radius: 10px;
            transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          }

          :after {
            transform: translateY(${opened ? 0 : 10}px)
              rotate(${opened ? 90 : 0}deg);
          }

          :before {
            transform: translateY(${opened ? 0 : -10}px);
          }
        `}
      ></div>
    </div>
  );
};

BurgerMenu.defaultProps = {
  opened: false,
  onClick: () => null,
};

export default BurgerMenu;
